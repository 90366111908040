import React, { useRef, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { Layout } from '@components';
import { usePrefersReducedMotion } from '@hooks';

const StyledBlogsSection = styled.section`
  display: flex;
  flex-direction: column;

  h2 {
    font-size: clamp(24px, 5vw, var(--fz-heading));
  }

  .post-link {
    font-family: var(--font-mono);
    font-size: var(--fz-sm);
    &:after {
      bottom: 0.1em;
    }
  }

  .blog-grid {
    ${({ theme }) => theme.mixins.resetList};
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-gap: 15px;
    position: relative;
    margin-top: 50px;

    @media (max-width: 1080px) {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }
`;
const StyledBlog = styled.li`
  position: relative;
  cursor: default;
  transition: var(--transition);

  @media (prefers-reduced-motion: no-preference) {
    &:hover,
    &:focus-within {
      .blog-inner {
        transform: translateY(-7px);
      }
    }
  }

  a {
    position: relative;
    z-index: 1;
  }

  .blog-inner {
    ${({ theme }) => theme.mixins.boxShadow};
    ${({ theme }) => theme.mixins.flexBetween};
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    height: 100%;
    padding: 2rem 1.75rem;
    border-radius: var(--border-radius);
    background-color: var(--light-bg);
    transition: var(--transition);
    overflow: auto;
    display: grid;
    grid-template-rows: auto 1fr auto;
    row-gap: 20px;
  }

  .blog-cover {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: var(--border-radius);
    grid-row: 1 / 2; /* Places the image at the top */
    margin-bottom: 35px;
  }

  .blog-title {
    margin: 0 0 10px;
    color: var(--lightest-text);
    font-size: var(--fz-xxl);
    grid-row: 2 / 3;

    a {
      position: static;

      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  .blog-description {
    color: var(--light-text);
    font-size: 17px;
    grid-row: 3 / 4; /* Places the description under the title */

    a {
      ${({ theme }) => theme.mixins.inlineLink};
    }

    overflow: hidden;
    text-overflow: ellipsis;
  }

  .blog-date {
    align-self: end;
    vertical-align: middle;
    justify-content: flex-start;
    font-family: var(--font-mono);
    font-size: var(--fz-xxs);
    line-height: 1.75;
  }

  .blog-tag-list {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    flex-wrap: wrap;
    padding: 0;
    margin: 20px 0 0 0;
    list-style: none;
    align-self: end; /* Aligns the tags to the bottom edge */
    justify-content: flex-end; /* Pushes the tags to the right */
    vertical-align: middle;

    li {
      font-family: var(--font-mono);
      font-size: var(--fz-xxs);
      line-height: 1.75;
      margin-right: 10px;
    }
  }

  footer {
    grid-row: 4 / 5; /* Places the footer under the description */
    display: inline-block;
    display: flex;
    flex-direction: row;
  }
`;
const BlogsPage = ({ location, data }) => {
  const posts = data.allMarkdownRemark.edges;
  const revealTitle = useRef(null);
  const revealPosts = useRef([]);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealTitle.current, srConfig());
    revealPosts.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  const blogInner = node => {
    const { frontmatter } = node;
    const { title, date, description, tags, coverImage, slug } = frontmatter;
    const image = getImage(coverImage);

    return (
      <div className="blog-inner">
        <header>
          <GatsbyImage image={image} alt={title} className="blog-cover" />
          <h3 className="blog-title">
            <Link to={slug}>{title}</Link>
          </h3>
          <div className="blog-description">
            <p>{description}</p>
          </div>
        </header>

        <footer>
          <small className="blog-date">{date}</small>
          {tags && (
            <ul className="blog-tag-list">
              {tags.map((tag, i) => (
                <li key={i}>{tag}</li>
              ))}
            </ul>
          )}
        </footer>
      </div>
    );
  };

  return (
    <Layout location={location}>
      <Helmet title="Blogs" />

      <main>
        <header ref={revealTitle}>
          <h1 className="big-heading">Blog</h1>
          <p className="subtitle">
            Thoughts on data visualization, data science, and Generative AI.
          </p>
        </header>

        <StyledBlogsSection>
          <ul className="blog-grid">
            {posts &&
              posts.map(({ node }, i) => <StyledBlog key={i}>{blogInner(node)}</StyledBlog>)}
          </ul>
        </StyledBlogsSection>
      </main>
    </Layout>
  );
};

BlogsPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default BlogsPage;

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/blogs/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            date
            title
            description
            tags
            slug
            coverImage {
              childImageSharp {
                gatsbyImageData(width: 600, placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  }
`;
